<template>
  <b-dropdown class="leave-by-selector">
    <div slot="trigger">
      {{
        $t("assignment.rightPanel.assign.leaveBy.title", { time: leaveByTime })
      }}
      <b-icon
        icon="chevron-right"
        size="is-small"/>
    </div>
    <b-dropdown-item
      v-for="option in leaveByOptions"
      :key="`leave_by_${option}`"
      @click="onSelect(option)">
      {{ $t(`assignment.rightPanel.assign.leaveBy.${option}`) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { LEAVE_BY_OPTIONS, ONE_MINUTE } from '@js/constants'
import { formatTime } from '@js/utils'

export default {
  name: 'leave-by-selector',
  props: {
    selectedOption: {
      type: Number,
      default: null
    },
    earliestStartTime: {
      type: Number,
      default: null
    }
  },
  computed: {
    leaveByOptions() {
      return LEAVE_BY_OPTIONS
    },
    leaveByTime() {
      if (
        (this.selectedOption || this.selectedOption === 0) &&
        this.earliestStartTime
      ) {
        const time = this.earliestStartTime - this.selectedOption * ONE_MINUTE
        return formatTime(time)
      }
      return null
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('update:selectedOption', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.leave-by-selector {
  display: block;
  cursor: pointer;

  span.icon {
    width: 0.75rem;
    @extend %small;
  }

  &::v-deep {
    .dropdown-item {
      white-space: normal;
      @extend %small;

      &:not(:last-child) {
        border-bottom: 1px solid $grey-lighter;
      }
    }
  }
}
</style>
